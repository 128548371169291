import { EXTENSIONS } from "./extensions";
import {
    addErrorClass,
    removeErrorClass,
} from "./extensions/collection/common/extra_error";
import { CheckboxField, Field, RadioField } from "./field";
import { Schema } from "./schemas/schema";
const instances = {};

export class FormValidator {
    form;
    schema;
    fields = new Map();
    submitter = undefined;

    /**
     * @param {string} selector
     * @param {string} schema
     * @param extensions
     */
    constructor(selector, schema, extensions = [], containerId = "") {
        this.form = document.getElementById(selector);
        this.submitter = document.getElementById(`${selector}-submitter`);
        this.schema = new Schema(schema, this.getCustomErrorMessages());
        this.submitErrorElement = this.form.querySelector(
            "[data-validator-error=submit]"
        );
        this.extensions = extensions;
        this.extraErrors = [];
        this.container = document.getElementById(containerId) ?? null;

        if (!this.form) {
            throw new Error(`Form element not found: ${selector}`);
        }

        this.buildFieldInstances();
        this.initExtensions();
        this.listenSubmit();
        this.listenInputFields();

        if (!instances[selector]) {
            instances[selector] = this;
        }
    }

    /**
     * Get all input fields in the form that contains the data-validator attribute.
     */
    get formFields() {
        return this.form.querySelectorAll("[data-validator]");
    }

    /**
     * Get all error message elements.
     */
    get errorMessageElements() {
        return this.form.querySelectorAll("[data-v-messages]");
    }

    /**
     * Build values object so that the keys are the validatorKey of the fields and the values are
     * the effectiveValue.
     * @returns {object}
     */
    get buildFieldValuesObject() {
        return [...this.fields].reduce((values, [key, field]) => {
            values[key] =
                field.effectiveValue === "" ? undefined : field.effectiveValue;
            return values;
        }, {});
    }

    /**
     * Get field by key.
     * @param {string} key
     * @returns {Field}
     */
    getField(key) {
        return [...this.fields].find(
            (field) => field[1].validatorKey === key
        )?.[1];
    }

    /**
     * Check if all fields are valid.
     * @returns {boolean}
     */
    get isValid() {
        return (
            [...this.fields].every((field) => !field[1].hasErrors) &&
            !this.hasExtraErrors()
        );
    }

    /**
     * Check if form is showing errors.
     * @returns {boolean}
     */
    showingErrors() {
        return [...this.fields].some((field) => field[1].hasErrors);
    }

    /**
     * Highlights container when form has error.
     */
    highlightContainer() {
        if (this.showingErrors() || this.hasExtraErrors()) {
            addErrorClass(this.container);
        } else {
            removeErrorClass(this.container);
        }
    }

    /**
     * Get single error object by key.
     * @returns {object}
     */
    getCustomError(key) {
        return this.getCustomErrorMessages()[key];
    }

    /**
     * Build error object so that the key are the validatorKey of the fields and the values are
     * the error messages.
     * @returns {object}
     */
    getCustomErrorMessages() {
        return [...this.errorMessageElements].reduce((prev, error) => {
            const messages = JSON.parse(error.dataset.vMessages);
            prev[error.dataset.validatorError] = messages;
            return prev;
        }, {});
    }

    /**
     * Create a instance of Field to a given input element.
     * @param {HTMLInputElement} element
     * @returns {Field}
     */
    instantiateField(element) {
        return element.type === "checkbox"
            ? new CheckboxField(element)
            : new Field(element);
    }

    /**
     * Create a instance of Field to a given input element.
     * @param {HTMLInputElement} element
     * @returns {Field}
     */
    instantiateRadioField(key, elements) {
        return new RadioField(key, elements);
    }

    /**
     * Populate fields Map by passing the validatorKey and its respective field.
     */
    buildFieldInstances() {
        const radios = {};

        [...this.formFields].forEach((field) => {
            if (field.type === "radio") {
                if (!radios[field.dataset.validator]) {
                    radios[field.dataset.validator] = {};
                }
                radios[field.dataset.validator][field.id] = field;
            } else {
                this.fields.set(
                    field.dataset.validator,
                    this.instantiateField(field)
                );
            }
        });

        Object.entries(radios).forEach(([key, fields]) => {
            this.fields.set(key, this.instantiateRadioField(key, fields));
        });
    }

    /**
     * Build error object based on zod issues.
     * @param {object[]} issues
     * @returns {object}
     */
    buildErrorObject(issues) {
        return issues.reduce((prev, issue) => {
            if (issue.code === "invalid_union") {
                issue.unionErrors[0].issues.forEach((unionIssue) => {
                    prev[unionIssue.path[0]] = unionIssue.message;
                });
                return prev;
            }
            prev[issue.path[0]] = issue.message;
            return prev;
        }, {});
    }

    /**
     * Update multiple error messages.
     * @param {object} errors
     */
    updateMultipleErrors(errors) {
        this.fields.forEach((field) => {
            const [errorSelector, errorClass] = this.getErrorSelector(
                field.element
            );

            if (errors[field.validatorKey]) {
                errorSelector.classList.add(errorClass);

                field.updateErrors([
                    ...field.errors,
                    errors[field.validatorKey],
                ]);
            }
        });
    }

    /**
     * Get all issue messages from a given validatorKey.
     * @param {Array} issues
     * @param {string} key
     * @returns {string[]}
     */
    getIssueMessages(issues, key) {
        if (!issues) {
            return [];
        }
        return issues
            .filter((issue) => issue.path[0] === key)
            .map((issue) => issue.message);
    }

    /**
     * Validate the whole form.
     * @returns {boolean}
     */
    validateForm(silent = false) {
        const fields = this.buildFieldValuesObject;
        const validation = this.schema.parseSchema(fields);

        if (validation.success && !this.hasExtraErrors()) {
            this.cleanErrors();
            return true;
        }

        if (!silent) {
            if (validation?.error?.issues) {
                const newErrors = this.buildErrorObject(
                    validation.error.issues
                );
                this.updateMultipleErrors(newErrors);
            }

            this.disableSubmit();
            this.highlightContainer();
        }
        return false;
    }

    /**
     * Validate the whole schema, but check error only for the given field.
     * @param {Field} field
     */
    validateField(field) {
        this.runBeforeValidateExtensions(field);
        this.runValidation(field);
        this.runAfterValidateExtensions(field);
    }

    /**
     * Remove all error messages from field.
     * @param {Field} field
     * @param {string} errorSelector
     */
    handleSuccessfulFieldValidation(field, errorSelector) {
        const [selector, className] = errorSelector;

        field.updateErrors([]);
        selector.classList.remove(className);
    }

    /**
     * Populate error messages for field.
     * @param {object} validation
     * @param {Field} field
     * @param {string} errorSelector
     */
    handleFailedFieldValidation(validation, field, errorSelector) {
        const [selector, className] = errorSelector;
        const issueMessages = this.getIssueMessages(
            validation?.error?.issues,
            field.validatorKey
        );

        if (issueMessages?.length) {
            field.element.dataset.isInvalid = true;
            field.updateErrors(issueMessages);
            selector.classList.add(className);
        } else {
            field.element.dataset.isInvalid = false;
            field.updateErrors([]);
            selector.classList.remove(className);
        }
    }

    /**
     * Get both errorSelector and errorClass data attributes from input element, which are used to define
     * the element responsible for controlling the error styling and the class to be applied in this element
     * when a error occurs.
     * @param {HTMLInputElement} field
     * @returns {[string, string]}
     */
    getErrorSelector(field) {
        const errorSelector = field.dataset.errorSelector
            ? field.closest(field.dataset.errorSelector)
            : field;

        const { errorClass } = field.dataset;

        return [errorSelector, errorClass];
    }

    /**
     * Clean errors and enables submit button.
     */
    cleanErrors() {
        this.updateMultipleErrors({});
        this.enableSubmit();
    }

    /**
     * Enable submit button and turn the submit error message into invisible.
     */
    enableSubmit() {
        if (!this.submitter) return;
        this.submitter.disabled = false;

        if (!this.submitErrorElement) return;
        this.submitErrorElement.classList.add("invisible");
    }

    /**
     * Disable submit button and turn the submit error message into visible.
     */
    disableSubmit() {
        if (!this.submitter) return;
        this.submitter.disabled = true;

        if (!this.submitErrorElement) return;
        this.submitErrorElement.classList.remove("invisible");
    }

    onSubmitForm() {
        this.runBeforeSubmitExtensions();
        if (this.validateForm()) {
            this.form.submit();
            this.runAfterSubmitExtensions();
        }
    }

    /**
     * Listen form submit event.
     */
    listenSubmit() {
        if (this.form.tagName === "FORM") {
            this.form.addEventListener("submit", (event) => {
                event.preventDefault();
                this.onSubmitForm();
            });
        } else {
            this.submitter.addEventListener("click", (event) => {
                event.preventDefault();
                this.runBeforeSubmitExtensions();
                if (this.validateForm()) {
                    this.runAfterSubmitExtensions();
                }
            });
        }
    }

    /**
     * Listen all the input fields in the form.
     */
    listenInputFields() {
        this.fields.forEach((formField) => {
            formField.listen(() => this.validateField(formField));
        });
    }

    runValidation(field) {
        const fields = this.buildFieldValuesObject;
        const validation = this.schema.parseSchema(fields);
        const errorSelector = this.getErrorSelector(field.element);

        if (validation.success && !this.hasExtraErrors()) {
            this.handleSuccessfulFieldValidation(field, errorSelector);
            this.enableSubmit();
            field.element.dataset.isInvalid = false;
        } else {
            this.handleFailedFieldValidation(validation, field, errorSelector);
        }
        this.highlightContainer();
    }

    runBeforeValidateExtensions(field) {
        this.extensions.forEach((extension) => {
            extension.beforeValidate(field);
        });
    }

    runAfterValidateExtensions(field) {
        this.extensions.forEach((extension) => {
            extension.afterValidate(field);
        });
    }

    runBeforeSubmitExtensions() {
        this.extensions.forEach((extension) => {
            extension.beforeSubmit();
        });
    }

    runAfterSubmitExtensions() {
        this.extensions.forEach((extension) => {
            extension.afterSubmit();
        });
    }

    hasExtraErrors() {
        return this.extraErrors.length !== 0;
    }

    addExtraError(error) {
        if (this.extraErrors.includes(error)) {
            return;
        }
        this.extraErrors.push(error);
    }

    removeExtraError(error) {
        this.extraErrors = this.extraErrors.filter((err) => err !== error);
    }

    initExtensions() {
        this.extensions.forEach((extension) => extension.init(this));
    }

    static getInstance(formId) {
        if (formId in instances) {
            return instances[formId];
        }

        const formValidator = document.getElementById(formId);

        if (!formValidator) {
            throw Error("Form validator not found!");
        }

        return new FormValidator(
            formValidator.id,
            formValidator.dataset.schema,
            getValidatorExtensions(formValidator),
            formValidator.dataset?.containerId ?? ""
        );
    }
}

function getValidatorExtensions(formValidator) {
    const extensionsStrings = formValidator.dataset.extend;

    if (!extensionsStrings) {
        return [];
    }

    const extensionsList = extensionsStrings.split(",");
    return extensionsList.map(
        (extensionString) => new EXTENSIONS[extensionString]()
    );
}

/**
 * Initialize FormValidator functionality.
 */
export function initFormValidator() {
    const formValidators = document.querySelectorAll(".form-validator");

    formValidators.forEach((formValidator) => {
        try {
            new FormValidator(
                formValidator.id,
                formValidator.dataset.schema,
                getValidatorExtensions(formValidator),
                formValidator.dataset?.containerId ?? ""
            );
        } catch (error) {
            console.error(error);
        }
    });
}
