import { validDate } from "../../../date_of_birth";
import { ExtraError, TreatmentStatus } from "../constants";
import { FormValidatorExtension } from "../extension";

export class TreatmentStopDateExtension extends FormValidatorExtension {
    constructor() {
        super();
    }

    init(formValidator) {
        this.formValidator = formValidator;
        this.error = document.getElementById("stop-date-error");
        this.errorGreater = document.getElementById("greater-stop-date-error");
        this.status = this.formValidator.getField("status");
        this.stop = this.formValidator.getField("stopDate");
        this.start = this.formValidator.getField("startDate");
    }

    isStopDateSmallerThanStartDate() {
        const stopDateValid = validDate(this.stop.effectiveValue);

        const stopValue = stopDateValid
            ? new Date(this.stop.effectiveValue)
            : null;
        const startValue = stopDateValid
            ? new Date(this.start.effectiveValue)
            : null;

        const isStopGreaterThanStart = stopDateValid && stopValue >= startValue;
        const isOngoingStatus =
            this.status.effectiveValue === TreatmentStatus.ONGOING;

        return stopDateValid && !isStopGreaterThanStart && !isOngoingStatus;
    }

    isStopDateValid() {
        return (
            (this.status.effectiveValue &&
                this.status.effectiveValue === TreatmentStatus.ONGOING) ||
            (this.status.effectiveValue !== TreatmentStatus.ONGOING &&
                this.stop.effectiveValue)
        );
    }

    beforeValidate(field) {
        if (!field.element.closest("#treatment-input-repeater")) {
            return;
        }

        if (this.isStopDateSmallerThanStartDate()) {
            this.formValidator.addExtraError(ExtraError.STOP_DATE_GREATER);
            this.errorGreater.classList.remove("invisible");
        } else {
            this.formValidator.removeExtraError(ExtraError.STOP_DATE_GREATER);
            this.errorGreater.classList.add("invisible");
        }

        if (this.isStopDateValid()) {
            this.formValidator.removeExtraError(ExtraError.STOP_DATE_REQUIRED);
            this.error.classList.add("invisible");
        }
    }

    beforeSubmit() {
        if (validDate(stop.effectiveValue)) {
            const stopValue = new Date(this.stop.effectiveValue);
            const startValue = new Date(this.start.effectiveValue);
            const isStopGreaterThanStart = stopValue > startValue;

            if (
                isStopGreaterThanStart ||
                this.status.effectiveValue === TreatmentStatus.ONGOING
            ) {
                this.formValidator.removeExtraError(
                    ExtraError.STOP_DATE_GREATER
                );
                this.errorGreater.classList.add("invisible");
            } else {
                this.formValidator.addExtraError(ExtraError.STOP_DATE_GREATER);
                this.errorGreater.classList.remove("invisible");
            }
        }

        if (
            this.status.effectiveValue !== "" &&
            this.status.effectiveValue !== "ongoing" &&
            !this.stop.effectiveValue
        ) {
            this.formValidator.addExtraError(ExtraError.STOP_DATE_REQUIRED);
            this.error.classList.remove("invisible");
        } else {
            this.formValidator.removeExtraError(ExtraError.STOP_DATE_REQUIRED);
            this.error.classList.add("invisible");
        }
    }
}
