/**
 * JS : Date Of Birth
 *
 * Product by Ocumetra
 *
 */
import { initAutotab } from "./autotab";
import { removeError } from "./error_validation_system";
import { checkAgeRangeError } from "../base.js";

let dateFormat = "dd-mm-yyyy"; // Default value
let objDateFormat = {};

/**
 * Init the Date of Birth (DOB) according to the date-format
 */
function initDOB() {
    const inputDOBs = document.getElementsByClassName("date-dob");

    if (!inputDOBs?.length) return;

    dateFormat = "dd-mm-yyyy";
    objDateFormat = splitDateFormat();

    for (const inputDOB of inputDOBs) {
        inputDOB.addEventListener("blur", () => {
            completeInputDOB(inputDOB);
            removeInvalidDOBError();
            onBlurDOB();
        });
        inputDOB.addEventListener("keyup", (e) => {
            removeInvalidDOBError();
            checkDateKeyUp(e, inputDOB);
        });
    }
    initAutotab(inputDOBs, [" ", ".", "-", "/"]);
}

/**
 * Remove custom dob error generated in the backend.
 */
function removeInvalidDOBError() {
    const invalidDOB = document.querySelector(".invalid-dob");

    if (!invalidDOB) {
        return;
    }

    invalidDOB.classList.add("d-none");
}

/**
 * Init and display all the date
 */
export function initDisplayDate() {
    const dates = document.getElementsByClassName("display-date");
    for (const date of dates) {
        // If no date is set, get the current date, otherwise get the formatted date
        if (date.textContent === "") {
            date.textContent = getDate();
        } else if (date.textContent.includes("-")) {
            const dateParts = date.textContent.split("-");
            // month keys are 0-based

            // reverse if date format is yyyy-mm-dd
            if (dateParts[0].length === 4) {
                dateParts.reverse();
            }

            const dateObject = new Date(
                +dateParts[2],
                dateParts[1] - 1,
                +dateParts[0]
            );
            date.textContent = getDate(dateObject);
        }
    }
}

/**
 * Split the date format to get an ordered array (exemple : ['DD','MM','YYYY']) and its separator
 * @returns JS object that contains split array and separator
 */
function splitDateFormat() {
    const tabSeparator = ["-", "."];
    let split;
    // Sets the split (array) and separator
    for (const sep of tabSeparator) {
        split = dateFormat.split(sep);
        if (split.length > 1) {
            return { split: split, separator: sep };
        }
    }
}

/**
 * On Blur : Complete a DOB input according to the type ('DD', 'MM' or 'YYYY')
 * Exemple : When the 'DD' input is filled in with '1', it is converted to '01'
 * @param {*} input
 */
function completeInputDOB(input) {
    const typeDate = input.id;
    let convertDate = "";
    input.value = input.value === "0" ? 1 : input.value;
    if (typeDate === "dob-yyyy") {
        while (input.value.length < 3 && input.value !== "")
            input.value = 0 + input.value;
        convertDate = input.value.length == 3 ? 2 + input.value : input.value;
    } else {
        convertDate = input.value.length == 1 ? 0 + input.value : input.value;
    }
    input.value = convertDate;
}

/**
 * On Blur : Call the showAge function when the whole date is completed.
 */
function onBlurDOB() {
    if (getInputDOBStr().length === dateFormat.length) {
        showAge();
        checkAgeRangeError();
    }
}

/**
 * Get the DOB to a string format
 * @returns
 */
function getInputDOBStr() {
    const [date0, date1, date2] = objDateFormat.split;
    const valueDOB0 = document.getElementById("dob-" + date0).value;
    const valueDOB1 = document.getElementById("dob-" + date1).value;
    const valueDOB2 = document.getElementById("dob-" + date2).value;
    // Create the DOB to a string format
    let strDOB = "";
    strDOB += valueDOB2;
    strDOB += objDateFormat.separator;
    strDOB += valueDOB1 === "" ? 0 : valueDOB1;
    strDOB += objDateFormat.separator;
    strDOB += valueDOB0 === "" ? 0 : valueDOB0;
    return strDOB;
}

/**
 * On Keyup : Check if the date is completed and shows the age, otherwise remove/hide the error
 * @param {*} event - event from Keyup
 * @param {*} input
 */
function checkDateKeyUp(event, input) {
    const containerDOB = document.querySelector(".container-dob");

    const key = event.key || event.code;
    // Calculate age only when the date is completed
    if (getInputDOBStr().length === dateFormat.length) {
        if (containerDOB) {
            containerDOB.style.visibility = "visible";
        }
        showAge();
    } else {
        if (containerDOB) {
            containerDOB.style.visibility = "hidden";
        }
        document.getElementById("current-age").style.display = "none";
        document.getElementById("error-range-dob").style.display = "none";
        document.getElementById("container-dob").removeAttribute("required");
        removeError($("#container-dob"));
    }
    // Remove the error on the DOB input when the key is different of Enter
    if (key !== "Enter") {
        removeError(input);
    }
}

/**
 * Shows the age when the age/date are validated, otherwise displays an error
 */
function showAge() {
    const strDOB = getInputDOBStr();
    const dateIsValid = validDate(strDOB);
    const errorRangeDOB = document.getElementById("error-range-dob");
    const containerDOB = document.getElementById("container-dob");
    const currentAge = document.getElementById("current-age");
    const currentAgeMonths = document.getElementById("current-age-months");
    const hasContainerDOBclass =
        containerDOB.classList.contains("container-dob");
    const shouldValidate = !errorRangeDOB.classList.contains("no-validate");

    function failDate(message) {
        currentAge.style.display = "none";
        containerDOB.setAttribute("required", "");
        errorRangeDOB.style.display = "inline-block";
        errorRangeDOB.innerText = message;
    }

    // Display/hide dob container for old form and meye gauge
    if (!dateIsValid && shouldValidate) {
        failDate("Invalid date");
    }

    // Display/hide dob container for new meye guide
    if (!shouldValidate && hasContainerDOBclass) {
        containerDOB.style.visibility = !dateIsValid ? "hidden" : "visible";
    }

    let ageYears;
    let ageMonths;

    if (validDate(strDOB)) {
        const visitDateInput = document.querySelector("input[name=visit_date");

        const visitDate = visitDateInput
            ? new Date(visitDateInput.value)
            : new Date();

        const convertDate = new Date(strDOB);

        ageYears = visitDate.getFullYear() - convertDate.getFullYear();
        ageMonths = visitDate.getMonth() - convertDate.getMonth();

        if (ageMonths < 0) {
            ageMonths = 12 + ageMonths;
            ageYears = ageYears - 1;
        }
    } else {
        return;
    }

    if (dateIsValid && !matchDate(strDOB)) {
        failDate("Invalid date");
    } else if (dateIsValid && ageYears < 19 && ageYears >= 6) {
        document.getElementById("age-years").innerText = ageYears;
        currentAge.style.display = "inline-block";

        window.dispatchEvent(
            new CustomEvent("valid-dob", { detail: { age: ageYears } })
        );

        if (ageMonths !== 0) {
            document.getElementById("age-months").innerText = ageMonths;
            currentAgeMonths.style.display = "inline-block";
        } else {
            currentAgeMonths.style.display = "none";
        }

        errorRangeDOB.style.display = "none";
        containerDOB.removeAttribute("required");
        removeError($("#container-dob"));
    } else if (ageYears >= 19 || ageYears < 6) {
        failDate("Insert a valid age between 6-18");
    }
}

/**
 * Get the date (today) according to the format/dateFormat
 * @param {*} format
 * @returns the current date
 */
export function getDate(date = "") {
    date = date ? date : Date.now();
    return new Intl.DateTimeFormat(["default", "en-GB"], {
        year: "numeric",
        month: "short",
        day: "2-digit",
    }).format(date);
}

/**
 * Check if the DOB is valid or not.
 * @param {string} value
 * @returns {boolean} true/false
 */
export function validDate(value) {
    return !isNaN(Date.parse(new Date(value)));
}

/**
 * Validates whether the input date corresponds to the values of a Date object.
 * This check prevents the evaluation of invalid dates, as JavaScript automatically
 * adjusts dates like "31-02-2010" to the next valid date.
 * @param {string} strDOB
 * @returns {boolean}
 */
export function matchDate(strDOB) {
    const [inputYear, inputMonth, inputDay] = strDOB.split("-");
    const date = new Date(strDOB);

    const dateYear = date.getUTCFullYear();
    const dateMonth = date.getUTCMonth();
    const dateDay = date.getUTCDate();

    return (
        Number(inputYear) === dateYear &&
        Number(inputMonth) - 1 === dateMonth &&
        Number(inputDay) === dateDay
    );
}

/**
 * Calculates age for a given date of birth.
 * @param {Date} dobDate
 * @returns {[number, number]}
 */
export function calculateAge(dobDate, referenceDate) {
    const refDate = referenceDate ? referenceDate : new Date();

    let ageYears = refDate.getFullYear() - dobDate.getFullYear();
    let ageMonths = refDate.getMonth() - dobDate.getMonth();

    if (ageMonths < 0) {
        ageMonths = 12 + ageMonths;
        ageYears = ageYears - 1;
    }

    return [ageYears, ageMonths];
}

/**
 * Recalculate age everytime visit date changes.
 */
function handleVisitDate() {
    const visitDateInput = document.querySelector("input[name=visit_date");

    if (!visitDateInput) {
        return;
    }

    visitDateInput.addEventListener("input", () => {
        showAge();
    });
}

// Launch the library to the load.
window.addEventListener("load", () => {
    initDOB();
    initDisplayDate();
    handleVisitDate();
});
