import { FormValidator } from "../../../form_validator";

const ERROR_CLASS = "error";
const INVISIBLE_CLASS = "invisible";

/**
 * Add error class to container.
 * @param {HTMLElement} container
 */
export function addErrorClass(container, className = ERROR_CLASS) {
    if (!container) return;
    container.classList.add(className);
}

/**
 * Remove error class from container.
 * @param {HTMLElement} container
 */
export function removeErrorClass(container, className = ERROR_CLASS) {
    if (!container) return;
    container.classList.remove(className);
}

/**
 * Add extra error and show error.
 * @param {FormValidator} formValidator
 * @param {HTMLElement} errorElement
 * @param {string} errorName
 */
export function showExtraError(formValidator, errorElement, errorName) {
    formValidator.addExtraError(errorName);
    errorElement.classList.remove(INVISIBLE_CLASS);
}

/**
 * Remove extra error and hide error.
 * @param {FormValidator} formValidator
 * @param {HTMLElement} errorElement
 * @param {string} errorName
 */
export function hideExtraError(formValidator, errorElement, errorName) {
    formValidator.removeExtraError(errorName);
    errorElement.classList.add(INVISIBLE_CLASS);
}
