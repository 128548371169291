import { ExtraError } from "../constants";
import { FormValidatorExtension } from "../extension";

export class TreatmentStartDateExtension extends FormValidatorExtension {
    constructor() {
        super();
    }

    init(formValidator) {
        this.formValidator = formValidator;
        this.visitRepeaterContainer = document.querySelector(
            ".repeater-container"
        );
        this.visitRepeater = document.getElementById("visit-input-repeater");
        this.errorStart = document.getElementById("start-date-beyond-error");
        this.stop = this.formValidator.getField("stopDate");
        this.start = this.formValidator.getField("startDate");
    }

    isStartDateValid() {
        const baseVisit = this.visitRepeaterContainer.querySelector(
            ".repeater-item-base"
        );
        const createdItems =
            this.visitRepeater.querySelectorAll(".repeater-item");
        const visitInputs = [baseVisit, ...createdItems].map(
            (visit) => visit.querySelector("input[name=visit-date]").value
        );
        const dates = visitInputs.map((value) => new Date(value));
        const maxDate = dates.reduce((a, b) => (a > b ? a : b));
        const startDate = new Date(this.start.effectiveValue);

        if (!maxDate || isNaN(maxDate)) {
            return;
        }

        return startDate <= maxDate;
    }

    beforeValidate(field) {
        if (!field.element.closest("#treatment-input-repeater")) {
            return;
        }

        if (this.isStartDateValid()) {
            this.formValidator.removeExtraError(ExtraError.START_DATE_BEYOND);
            this.errorStart.classList.add("invisible");
        } else {
            this.formValidator.addExtraError(ExtraError.START_DATE_BEYOND);
            this.errorStart.classList.remove("invisible");
        }
    }

    beforeSubmit() {
        if (this.isStartDateValid()) {
            this.formValidator.removeExtraError(ExtraError.START_DATE_BEYOND);
            this.errorStart.classList.add("invisible");
        } else {
            this.formValidator.addExtraError(ExtraError.START_DATE_BEYOND);
            this.errorStart.classList.remove("invisible");
        }
    }
}
