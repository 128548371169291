/**
 * Swap between two classes.
 * @param {HTMLElement} element
 * @param {string} class1
 * @param {string} class2
 * @param {boolean} condition
 */
export function swapClasses(element, class1, class2) {
    element.classList.add(class1);
    element.classList.remove(class2);
}

/**
 * Swap classes according to the given condition.
 * @param {boolean} condition
 * @param {HTMLElement} element
 * @param {string} class1
 * @param {string} class2
 */
export function swapClassesIf(condition, element, class1, class2) {
    if (condition) {
        swapClasses(element, class1, class2);
    } else {
        swapClasses(element, class2, class1);
    }
}

/**
 * Restrict a value between a minimum and maximum range.
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns number
 */
export function valueInRange(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

/**
 * Run element callback once if initCondition is true and add listener.
 *
 * @param {HTMLElement} element
 * @param {boolean} initCondition
 * @param {string} eventName
 * @param {(HTMLElement) => any} callback
 */
export function initThenListen(element, initCondition, eventName, callback) {
    if (initCondition) {
        callback(element);
    }

    element.addEventListener(eventName, () => callback(element));
}

/**
 *
 * @param {HTMLInputElement} input
 * @param {any} value
 */
export function setInputValue(input, value) {
    input.value = value;
    input.setAttribute("value", value);
}

/**
 * Animate numeric progression.
 * @param {string} idDiv
 * @param {number} initVal
 * @param {number} lastVal
 * @param {number} duration
 */
export function animateNumericProgression(idDiv, initVal, lastVal, duration) {
    const obj = document.getElementById(idDiv);
    let calculatedValue;
    let startTime = null;

    function step(currentTime) {
        if (!startTime) {
            startTime = currentTime;
        }
        // calculate the value to be used in calculating the number to be displayed
        const progress = Math.min((currentTime - startTime) / duration, 1);

        // calculate what to be displayed using the value above
        calculatedValue = Math.floor(progress * (lastVal - initVal) + initVal);
        obj.innerText = calculatedValue.toLocaleString();

        // checking to make sure the counter does not exceed the last value(lastVal)
        if (progress < 1) {
            window.requestAnimationFrame(step);
        } else {
            window.cancelAnimationFrame(window.requestAnimationFrame(step));
        }
    }
    // start animating
    window.requestAnimationFrame(step);
}

/** Uppercase first letter of string;
 * @param {string} str
 * @returns {string}
 */
export function upperCaseFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Lowercase first letter of string;
 * @param {string} str
 * @returns {string}
 */
export function lowerCaseFirstLetter(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

/**
 * Remove substring from string;
 * @param {string} str
 * @returns {string}
 */
export function cut(str, strToRemove) {
    return str.replace(strToRemove, "");
}

/**
 * Reduce Object.entries() with the given condition.
 * @param {object} obj
 * @param {(key: string, value: any) => boolean} conditionFunction
 * @returns {object}
 */
export function reduceEntries(obj, conditionFunction) {
    return Object.entries(obj).reduce((prev, [key, value]) => {
        if (conditionFunction(key, value)) {
            prev[key] = value;
        }
        return prev;
    }, {});
}

/**
 *
 * @param {HTMLElement} container
 * @returns {NodeListOf<HTMLInputElement>}
 */
export function getInputs(container) {
    return container.querySelectorAll("input,select");
}

/**
 * Check if the viewport width is within the deskop breakpoint.
 * @returns {boolean}
 */
export function isDesktopBreakpoint() {
    return document.documentElement.clientWidth >= 1024;
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
export function kebabToCamelCase(str) {
    return lowerCaseFirstLetter(
        str
            .split("-")
            .map((piece) => upperCaseFirstLetter(piece))
            .join("")
    );
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
export function snakeToCamelCase(str) {
    return lowerCaseFirstLetter(
        str
            .split("_")
            .map((piece) => upperCaseFirstLetter(piece))
            .join("")
    );
}

/**
 * Validate if value is in the right range.
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns {boolean}
 */
export function validRange(value, min, max) {
    return value >= min && value <= max;
}

/**
 * Formats date into ISO string pattern.
 * @param {Date} date
 */
export function formatISODate(date) {
    return date.toISOString().slice(0, 10);
}

/**
 * Gets a format pattern and apply it to a value accordingly.
 * @param {string} format
 * @param {number|string} value
 * @returns {[number|string, string]}
 */
export function parseFormat(format, value, nan = false) {
    const _value = value ? parseFloat(value).toFixed(2) : value;

    if (nan && isNaN(_value)) {
        return value;
    }

    switch (format) {
        case "0.00D":
            return [_value, "D"];
        case "0.00mm":
            return [_value, "mm"];
        case "0":
        default:
            return [value, ""];
    }
}

/**
 * @param {string} str
 * @param {string} pattern
 * @returns {boolean}
 */
export function like(str, pattern) {
    const escapedPattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp("^" + `.*${escapedPattern}.*` + "$", "i");
    return regex.test(str);
}

/**
 * Format date to dd/mm/YYYY format.
 * @param {string} inputDate
 * @returns {string}
 */
export function formatDate(inputDate) {
    const date = new Date(inputDate);

    return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    }).format(date);
}

/*
 * Check if user agent is a mobile device.
 * @returns {boolean}
 */
export function isMobileUserAgent() {
    return !(navigator.userAgent.indexOf("Mobile") <= 0);
}

/**
 * Converts a string input into a boolean
 * value based on a list of valid options.
 * @returns {boolean}
 */
export function stringToBoolean(input, validOptions = ["true", "false"]) {
    const inputLower = input.toLowerCase();
    if (inputLower === "true") {
        return true;
    }

    if (!(inputLower in validOptions)) {
        return false;
    }

    return false;
}

/**
 * Checks if all values in an array are non-empty values.
 * @param {any[]} values
 * @returns {boolean}
 */
export function areAllValuesPresent(values) {
    return values.every((value) => !!value || value === 0);
}

/**
 * Calculates the absolute time difference
 * between two dates in years.
 * @param {Date} date1
 * @param {Date} date2
 * @returns {float}
 */

export function calculateYearDifference(date1, date2) {
    let diff = (date1.getTime() - date2.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(diff / 365.25);
}

/**
 * Checks if there are some dates where the time difference in years
 * between them are lower than the given limit.
 * @param {string[]} dates
 * @param {float} limit
 * @returns {boolean}
 */
export function areDatesBelowLimit(dates, limit) {
    const sortedDates = dates.toSorted((a, b) => new Date(a) - new Date(b));

    for (let i = 0; i < sortedDates.length - 1; i++) {
        const first = new Date(sortedDates[i]);
        const second = new Date(sortedDates[i + 1]);

        if (calculateYearDifference(first, second) < limit) {
            return true;
        }
    }

    return false;
}
