import { isBaseRowEmpty } from "./form_validator/extensions/collection/common/visit_repeater";
import { FormValidator } from "./form_validator/form_validator";

const MS_PER_YEAR = 365.2425 * 24 * 60 * 60 * 1000;

/**
 * Calculate age from a referencial date.
 * @param {Date} dob
 * @param {Date} refDate
 * @returns {number}
 */
export function calculateAgeInDecimal(dob, refDate = new Date()) {
    const diffMs = refDate - dob;
    return (diffMs / MS_PER_YEAR).toFixed(1);
}

/**
 * Create age input to avoid sending date of birth
 * information to the backend.
 * @param {Date} dob
 * @param {HTMLElement} context
 */
export function createAgeInput(dob, context) {
    const age = calculateAgeInDecimal(dob);
    createHiddenInput("age", age, context);
}

/**
 * Create hidden input element.
 * @param {string} name
 * @param {*} value
 * @param {HTMLElement} context
 */
export function createHiddenInput(name, value, context) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    context.appendChild(input);
}

/**
 * Create age list input to avoid sending date of birth information
 * to the backend.
 * @param {Date} dob
 * @param {HTMLElement} context
 * @param {string} name
 */
export function createAgeListInput(dob, context, name, repeaterId) {
    const dates = document.getElementsByName(name);
    const ages = [];
    const [isEmpty] = isBaseRowEmpty(repeaterId);

    dates.forEach((dateElement) => {
        if (isEmpty && dateElement.id === name) {
            return;
        }

        if (dateElement.value) {
            const date = new Date(dateElement.value);
            const age = calculateAgeInDecimal(dob, date);
            ages.push(age);
        } else {
            ages.push("");
        }
    });

    const ageName = name.replace("date", "ages");
    createHiddenInput(ageName, JSON.stringify(ages), context);
}

/**
 * Retrives list of DOB inputs ordered as yyyy,mm,dd.
 * @returns {HTMLInputElement[]}
 */
function getDobInputs() {
    return [
        document.getElementById("dob-yyyy"),
        document.getElementById("dob-mm"),
        document.getElementById("dob-dd"),
    ];
}

/**
 * Build DOB date with format yyyy/mm/dd.
 * @param {HTMLInputElement} yyyyInput
 * @param {HTMLInputElement} mmInput
 * @param {HTMLInputElement} ddInput
 * @returns {Date}
 */
function buildDobDate(yyyyInput, mmInput, ddInput) {
    return new Date(`${yyyyInput.value}/${mmInput.value}/${ddInput.value}`);
}

/**
 * Set the DOB input values to zero to prevent the real
 * values from being sent to the backend.
 * @param {HTMLInputElement} yyyyInput
 * @param {HTMLInputElement} mmInput
 * @param {HTMLInputElement} ddInput
 */
function clearDobInputs(yyyyInput, mmInput, ddInput) {
    yyyyInput.value = "0000";
    mmInput.value = "00";
    ddInput.value = "00";
}

/**
 * Calculate age from dob input on meye forms.
 * @param {HTMLFormElement} form
 */
function onSubmitMeyeWithDob(form) {
    const [yyyyInput, mmInput, ddInput] = getDobInputs();
    const dob = buildDobDate(yyyyInput, mmInput, ddInput);

    createAgeInput(dob, form);
    clearDobInputs(yyyyInput, mmInput, ddInput);
}

/**
 * Calculate age from dob input on meye score forms.
 * @param {HTMLFormElement} form
 */
function onSubmitScoreWithDob(form) {
    const [yyyyInput, mmInput, ddInput] = getDobInputs();
    const dob = buildDobDate(yyyyInput, mmInput, ddInput);

    createAgeListInput(dob, form, "visit-date", "visit-input-repeater");
    createAgeListInput(dob, form, "start-date", "treatment-input-repeater");
    createAgeListInput(dob, form, "stop-date", "treatment-input-repeater");
    clearDobInputs(yyyyInput, mmInput, ddInput);
}

/**
 * Adds listener to meye forms to handle age calculations.
 * @param {HTMLFormElement} form
 * @param {boolean} validate
 */
function handleMeyeDob(form, validate = false) {
    if (!form) {
        return;
    }

    form.addEventListener("submit", (event) => {
        event.preventDefault();

        const shouldSubmit =
            !validate || FormValidator.getInstance(form.id).validateForm();

        if (shouldSubmit) {
            onSubmitMeyeWithDob(form);
            form.submit();
        }
    });
}

/**
 * Creates listener to meye score forms to handle age calculations.
 * @param {HTMLFormElement} form
 * @param {boolean} validate
 */
function handleScoreDob(form, validate = false) {
    if (!form) {
        return;
    }

    form.addEventListener("submit", (event) => {
        event.preventDefault();

        const shouldSubmit =
            !validate || FormValidator.getInstance(form.id).validateForm();

        if (shouldSubmit) {
            onSubmitScoreWithDob(form);
            form.submit();
        }
    });
}

/**
 * Get whichever score form is available.
 * @returns {HTMLFormElement}
 */
function getScoreForm() {
    const form =
        document.getElementById("submit-new-score-form") ||
        document.getElementById("submit-use-score-form") ||
        document.getElementById("submit-update-score-form");

    return form;
}

/**
 * Create hidden inputs with age values calculated from the visit dates,
 * start dates and stop dates.
 */
export function treatDobInputs() {
    const guideForm = document.getElementById("submit-guide-form");
    const alestForm = document.getElementById("submit-alest-form");
    const scoreForm = getScoreForm();

    handleScoreDob(scoreForm, true);
    handleMeyeDob(guideForm, true);
    handleMeyeDob(alestForm);
}
