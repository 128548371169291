/**
 * Extra errors added to form validator extensions.
 */
export const ExtraError = Object.freeze({
    VISIT_DATA: "visit-data",
    VISIT_COUNT: "visit-count",
    UNIQUE_VISIT_DATES: "unique-visit-dates",
    TREATMENT_DATA: "treatment-data",
    START_DATE_BEYOND: "start-date-beyond",
    STOP_DATE_REQUIRED: "stop-date-required",
    STOP_DATE_GREATER: "stop-date-greater",
    YEAR_DIFF: "year-diff",
    AGE_RANGE: "age-range",
});

/**
 * Possible treatment status to be selected on meye score form.
 */
export const TreatmentStatus = Object.freeze({
    ONGOING: "ongoing",
    STOPPED: "stopped",
});

/**
 * The minimum time difference in years the data
 * points on meye score should have between each other.
 */
export const YEAR_DIFF_LIMIT = 0.2;
