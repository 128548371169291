// eslint-disable-next-line no-unused-vars
import { FormValidator } from "../../../form_validator";
import {
    areDatesBelowLimit,
    empty,
    formatDate,
    formatStringToYMD,
} from "../../../../utils";
import { YEAR_DIFF_LIMIT } from "../../constants";
import { calculateAgeInDecimal } from "../../../../../libs/age";
import { validDate, validDateInstance } from "../../../../date_of_birth";

export const BASE_ITEM_CLASS = ".repeater-item-base";
export const ITEM_CLASS = ".repeater-item";

/**
 * Get all repeater rows.
 * @param {string} repeaterId
 * @param {boolean} allowEmpty
 * @returns {HTMLElement[]}
 */
export function getRepeaterRows(repeaterId, allowEmpty = true) {
    const repeater = document.getElementById(repeaterId);

    if (!repeater) {
        return null;
    }

    const baseItems = repeater.querySelectorAll(BASE_ITEM_CLASS);
    const items = repeater.querySelectorAll(ITEM_CLASS);
    const [isEmpty] = isBaseRowEmpty(repeaterId);

    if (!allowEmpty && isEmpty) {
        return [...items];
    }

    return [...baseItems, ...items];
}
/**
 * Checks if repeater base row is empty.
 * @param {string} repeaterId
 * @returns {[boolean, NodeListOf<HTMLInputElement>]}
 */
export function isBaseRowEmpty(repeaterId) {
    const repeater = document.getElementById(repeaterId);
    const baseItems = repeater.querySelectorAll(BASE_ITEM_CLASS);

    if (!baseItems.length) {
        return [true, []];
    }

    const allInputs = [...baseItems].map((base) => [
        ...base.querySelectorAll("input,select"),
    ]);

    const areInputsEmpty = allInputs.some((inputs) =>
        areRegularInputsEmpty(inputs)
    );
    const areRadiosEmpty = allInputs.some((inputs) =>
        areRadioInputsEmpty(inputs)
    );
    const isEmpty = areInputsEmpty && areRadiosEmpty;

    return [isEmpty, allInputs[0]];
}

/**
 * Get all inputs from repeater base rows.
 * @param {string} repeaterId
 * @returns {NodeListOf<HTMLInputElement>}
 */
export function getBaseRepeaterInputs(repeaterId) {
    const repeater = document.getElementById(repeaterId);
    const baseItems = repeater.querySelectorAll(BASE_ITEM_CLASS);
    if (!baseItems.length) {
        return [];
    }
    return [...baseItems].map((base) => [
        ...base.querySelectorAll("input,select"),
    ]);
}

/**
 * Check if all inputs are empty.
 * @param {NodeListOf<HTMLInputElement>} inputs
 * @returns {boolean}
 */
export function areRegularInputsEmpty(inputs) {
    return [...inputs]
        .filter((input) => input.type !== "date" && input.type !== "radio")
        .every((input) => empty(input.value));
}

/**
 * Check if all radio inputs are unchecked.
 * @param {NodeListOf<HTMLInputElement>} inputs
 * @returns {boolean}
 */
export function areRadioInputsEmpty(inputs) {
    return [...inputs]
        .filter((input) => input.type === "radio")
        .every((input) => !input.checked);
}

/**
 * Checks if visit row inputs are empty.
 * @param {HTMLElement} row
 * @returns {boolean}
 */
export function isVisitRowEmpty(row) {
    const inputs = row.querySelectorAll("input,select");

    return [...inputs]
        .filter((input) => input.type !== "date")
        .every((input) => empty(input.value));
}

/**
 * Disables repeater base row if it is empty.
 * @param {FormValidator} formValidator
 * @param {string} repeaterId
 */
export function disableEmptyRow(formValidator, repeaterId) {
    const [isEmpty, inputs] = isBaseRowEmpty(repeaterId);

    formValidator.validateForm(true, false);

    if (formValidator.isValid && isEmpty) {
        inputs.forEach((input) => {
            input.disabled = true;
        });
    }
}

export function disableRows(repeaterId, isPartiallyEmpty = false) {
    const [isEmpty, inputs] = isBaseRowEmpty(repeaterId);

    if (isEmpty || isPartiallyEmpty) {
        inputs.forEach((input) => {
            input.disabled = true;
        });
    }
}

/**
 * Get all date inputs from visits repeater.
 * @param {NodeListOf<HTMLElement>} visits
 * @returns {NodeListOf<HTMLInputElement>}
 */
export function getVisitDatesInputs(visits) {
    return visits.map((item) => item.querySelector("input[name='visit-date']"));
}

/**
 * Get all date inputs from visits repeater.
 * @param {string} repeaterId
 * @returns {NodeListOf<HTMLInputElement>}
 */
export function getFilteredVisitDatesInputs(repeaterId) {
    const visits = getRepeaterRows(repeaterId);
    const dateInputs = getVisitDatesInputs(visits);

    const [isEmpty] = isBaseRowEmpty(repeaterId);

    const filteredDateInputs = isEmpty
        ? dateInputs.filter((input) => input.id !== "visit-date")
        : dateInputs;

    return filteredDateInputs;
}

/**
 * Get inserted visit dates in yyyy-mm-dd format.
 * @returns {string[]}
 */
export function getInsertedVisitDates() {
    const insertedVisitsContainer = document.getElementById(
        "visits-table-container"
    );

    if (!insertedVisitsContainer) {
        return [];
    }

    const insertedVisitDateElements = insertedVisitsContainer.querySelectorAll(
        ".inserted-visit-date"
    );
    return [...insertedVisitDateElements].map((date) =>
        formatStringToYMD(date.innerText)
    );
}

/**
 * Get inputted visit dates in yyyy-mm-dd format.
 * @param {string} repeaterId
 * @returns {string[]}
 */
export function getInputtedVisitDates(repeaterId) {
    const visitItems = getRepeaterRows(repeaterId);
    const dateInputs = getVisitDatesInputs(visitItems);
    const [isEmpty] = isBaseRowEmpty(repeaterId);

    const filteredDateInputs = isEmpty
        ? dateInputs.filter((input) => input.id !== "visit-date")
        : dateInputs;

    return filteredDateInputs
        .map((input) => input.value)
        .filter((value) => !empty(value));
}

/**
 * Checks if the repeater visit dates are unique.
 * @returns {boolean}
 */
export function areVisitDatesUnique(repeaterId) {
    const inputtedDates = getInputtedVisitDates(repeaterId);
    const insertedDates = getInsertedVisitDates();

    const dateValues = [...inputtedDates, ...insertedDates];
    const uniqueDates = new Set(dateValues);

    return dateValues.length === uniqueDates.size;
}

/**
 * Get date instance from dob inputs.
 * @returns {Date}
 */
export function getDateFromDobOrAge() {
    const dateInput = document.getElementById("dob-date");

    if (dateInput) {
        return new Date(dateInput.value);
    }

    const ageInput = document.getElementById("age-value");

    if (ageInput && ageInput.value) {
        const today = new Date();
        const age = parseFloat(ageInput.value);

        const years = Math.floor(age);
        const months = Math.floor((age - years) * 12);

        return new Date(
            today.getFullYear() - years,
            today.getMonth() - months,
            today.getDate()
        );
    }
    return null;
}
/**
 * Check if age is out of range
 * @param {string|number} age
 * @returns {boolean}
 */
export function isAgeOutOfRange(age) {
    const floatAge = parseFloat(age);
    const outOfRange = 6 > floatAge || floatAge > 18;
    return outOfRange || isNaN(floatAge);
}

/**
 *
 * @param {NodeListOf<HTMLInputElement>} inputs
 * @returns {number[]}
 */
export function getRepeaterAges(inputs) {
    const dob = getDateFromDobOrAge();

    if (!validDateInstance(dob)) {
        return [];
    }

    return inputs.map((input) =>
        calculateAgeInDecimal(dob, new Date(input.value))
    );
}

/**
 * Checks if the repeater visit dates have the right age range.
 * @returns {boolean}
 */
export function hasCorrectAgeRange() {
    const filteredDateInputs = getFilteredVisitDatesInputs(
        "visit-input-repeater"
    );
    const ageValues = getRepeaterAges(filteredDateInputs);
    return ageValues.every((age) => !isAgeOutOfRange(age));
}

/**
 * Checks if repeater has a certain number of rows.
 * @param {string} repeaterId
 * @param {number} rows
 */
export function hasEnoughRows(repeaterId, rows) {
    const items = getRepeaterRows(repeaterId);
    const [isEmpty] = isBaseRowEmpty(repeaterId);

    const totalRows = isEmpty ? rows + 1 : rows;

    return items.length >= totalRows;
}

export function getVisitDates(repeaterId, allowEmpty = false) {
    const visits = getRepeaterRows(repeaterId, allowEmpty);
    const dateInputs = getVisitDatesInputs(visits);
    const insertedDates = getInsertedVisitDates().map((date) => new Date(date));
    const inputtedDates = dateInputs.map((item) => new Date(item.value));
    return [...insertedDates, ...inputtedDates];
}

export function isTimeDifferenceValid(repeaterId, allowEmpty = false) {
    const dates = getVisitDates(repeaterId, allowEmpty);
    const divergingDates = areDatesBelowLimit(dates, YEAR_DIFF_LIMIT);
    return !divergingDates;
}

export function isStartDatePastLatestVisit() {
    const rows = getRepeaterRows("visit-input-repeater");
    const treatmentRepeater = document.getElementById(
        "treatment-input-repeater"
    );
    const visitInputs = getVisitDatesInputs(rows);
    const start = treatmentRepeater.querySelector(
        "input[name=start-date]"
    ).value;

    const dates = visitInputs.map((input) => new Date(input.value));
    const maxDate = dates.reduce((a, b) => (a > b ? a : b));
    const startDate = new Date(start);

    if (!maxDate || isNaN(maxDate)) {
        return false;
    }

    return startDate > maxDate;
}

/**
 *
 * @param {string} inputDate
 * @param {string} selectDate
 * @returns {Date}
 */
export function getStartDate(inputDate, selectDate) {
    return validDate(inputDate) ? new Date(inputDate) : new Date(selectDate);
}

/**
 * Get input element from select container.
 * @param {HTMLSelectElement} selectElement
 * @returns {HTMLInputElement}
 */
export function getInputFromSelectContainer(selectElement) {
    const selectStartDate = selectElement.closest(".select-input-container");
    return selectStartDate?.querySelector("input");
}

/**
 * Clean select/input if option value matches select value.
 * @param {HTMLSelectElement} selectElement
 * @param {HTMLOptionElement} option
 */
export function cleanOptionValue(selectElement, option) {
    if (selectElement.value === option.value) {
        const dateInput = getInputFromSelectContainer(selectElement);
        selectElement.value = "";
        dateInput.value = "";
    }
}

/**
 * Function to sync custom visit dates (new user inputs) with the select element.
 * Ensures that options reflect only the current set of visit dates.
 * @param {HTMLSelectElement} selectElement
 * @param {NodeListOf<HTMLInputElement>} visitDateInputs
 */
export function addCustomVisitDates(selectElement, visitDateInputs) {
    const dateInputs = document.querySelectorAll("input[name='visit-date']");
    const dates = [...dateInputs].map((input) => input.value);
    const { options } = selectElement;

    [...options].forEach((option) => {
        const shouldRemove =
            option.classList.contains("custom-added") &&
            !dates.includes(option.value);

        if (shouldRemove) {
            cleanOptionValue(selectElement, option);
            selectElement.removeChild(option);
        }
    });

    // Add updated visit date options based on current inputs
    visitDateInputs.forEach((input) => {
        const dateValue = input.value;
        const optionValueMatchesDateValue =
            dateValue && ![...options].some((opt) => opt.value === dateValue);

        if (optionValueMatchesDateValue) {
            const option = document.createElement("option");
            option.value = dateValue;
            option.textContent = formatDate(dateValue);
            // Mark this option as dynamically added
            option.classList.add("custom-added");
            selectElement.appendChild(option);
        }
    });

    // Sort options in ascending order
    [...selectElement.querySelectorAll("option")]
        .filter((option) => !option.id)
        .sort((a, b) => new Date(a.value) - new Date(b.value))
        .forEach((node) => selectElement.appendChild(node));

    // Option to enter a custom date at the end
    const customDateOption = selectElement.querySelector(
        "#option-enter-custom"
    );
    if (customDateOption) {
        selectElement.appendChild(customDateOption);
    }
}

/**
 * Get the most recent visit date.
 * @param {string} repeaterId
 * @returns {Date}
 */
export function getMostRecentVisitDate(repeaterId) {
    const inputtedDates = getInputtedVisitDates(repeaterId);
    const insertedDates = getInsertedVisitDates();

    const dates = [...inputtedDates, ...insertedDates].map(
        (date) => new Date(date)
    );
    return dates.reduce((a, b) => (a > b ? a : b));
}

/**
 *
 * @param {number | undefined} rowNumber
 */
export function getVisitRowId(rowNumber = undefined) {
    return rowNumber ? `visit-row-${rowNumber}` : "visit-row";
}
