import { FormValidatorExtension } from "../extension";

export class LoaderExtension extends FormValidatorExtension {
    constructor() {
        super();
        this.loader = null;
    }

    init(formValidator) {
        this.formValidator = formValidator;
        this.loader = document.querySelector(
            `#loader-${this.formValidator.form.id}`
        );
        this.handleDisableFormLoader(this.loader);
    }

    handleDisableFormLoader() {
        window.addEventListener("beforeunload", () =>
            this.onBeforeUnloadFormLoader(this.loader)
        );
    }

    onBeforeUnloadFormLoader(loader, timeout = 10000) {
        setTimeout(() => {
            document.documentElement.style.pointerEvents = "none";
            loader.classList.add("d-none");
        }, timeout);
    }

    afterSubmit() {
        if (this.formValidator.form.classList.contains("form-with-loader")) {
            this.loader.classList.remove("d-none");
        }
    }
}
